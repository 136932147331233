<script setup lang="ts">
const { t } = useT();
const { scratchCardsIsActive } = useFeaturesIsActive();
const { activeCard, SKIN_CAMP } = useScretchCardData({ immediate: scratchCardsIsActive.value });

const { durationLeft: cardTimer, reset } = useCountdown({
	timestamp: activeCard.value?.timer ?? "",
	formatToken: "HH:mm:ss",
	isOptimized: true
});
watch(
	() => activeCard.value?.timer,
	() => {
		if (activeCard.value?.timer !== null) {
			reset(activeCard.value?.timer ?? "");
		}
	}
);
const handleGoToScratchClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "scratch_cards"
	});
	navigateTo("/scratch-cards");
};
</script>
<template>
	<div @click="handleGoToScratchClick()">
		<div class="icon">
			<NuxtImg
				src="/nuxt-img/rewards/scretch-cards.png"
				width="82"
				height="82"
				quality="100"
				format="webp"
				alt="scretch card"
			/>
		</div>

		<div class="content">
			<AText class="color-neutral-100 title" variant="toledo" :modifiers="['bold', 'uppercase']" as="div">
				{{ SKIN_CAMP ? "Scratch Camp" : t("Lucky Scratch") }}
			</AText>
			<MScratchCardsProgress
				:value="activeCard?.progress?.pointsComplete"
				:maxValue="activeCard?.progress?.points"
				class="small"
			/>
		</div>
		<AButton
			:variant="activeCard?.timer ? 'secondary' : 'primary'"
			:modifiers="[activeCard?.timer ? 'disabled' : '']"
			:class="['btn', { 'time-not-jump': activeCard?.timer }]"
			size="s"
		>
			<AText variant="topeka" :modifiers="['uppercase']">{{ activeCard?.timer ? cardTimer : t("Jump in") }}</AText>
		</AButton>
	</div>
</template>
<style lang="scss" scoped>
.icon {
	display: flex;
	align-items: center;

	img {
		width: 52px;
		height: 40px;
		object-fit: contain;
	}
}
.progress-wrap {
	width: 80%;
	margin-top: gutter(1);
	:deep(.nuxt-icon svg) {
		margin-bottom: 0;
	}
}
.btn {
	--a-button-secondary-background: var(--custom-9);
	--a-button-secondary-disabled-background: var(--custom-9);
	--a-button-secondary-disabled-color: var(--neutral-100);
	&.time-not-jump span {
		font-size: 13px;
	}
}
</style>
